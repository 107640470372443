import React from 'react'
import { Title } from '@UI'
import { Fade } from '@animations'
import * as styles from './thirdsColorBlock.module.scss'
import { useCurrentWidth } from '@hooks'

const ThirdsColorBlock = ({ data }) => {
  const width = useCurrentWidth()

  return (
    <section className={styles.container}>
      {data.map(({ title, body }, i) => (
        <div className={styles.section} key={body}>
          <Fade
            className={styles.sectionInner}
            delay={width < 1000 ? 0 : i * 0.2}
            duration={0.9}
          >
            <Title sm tag='p'>
              {title}
            </Title>
            <p className={styles.body}>{body}</p>
          </Fade>
        </div>
      ))}
    </section>
  )
}

export default ThirdsColorBlock
