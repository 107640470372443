import React from 'react'
import { Button } from '@UI'
import { TitleBodyBlock } from '@blocks'

const Intro = () => {
  return (
    <TitleBodyBlock
      label='Website Design and Development in Cody, WY'
      title='Get More Than Just a Website'
    >
      <p>
        A clean, intuitive website that matches your unique brand can help keep
        visitors on your site longer and increase brand loyalty. But a great
        website isn't only about looks.
      </p>

      <p>
        A great website needs to be strategy-driven in order to be more easily
        found by Google, to be more accessible to users, and to guide visitors
        to take specific action.
      </p>
      <Button to='/contact' cta>
        Get Started
      </Button>
    </TitleBodyBlock>
  )
}

export default Intro
