import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { HeroBlock } from '@blocks'

// import * as styles from './hero.module.scss'

const Hero = () => {
  const image = useStaticQuery(graphql`
    query ServicesHeroQuery {
      file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "services-hero" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <HeroBlock
      image={image.file}
      titleTop='Web Design and Development'
      body='Responsive, conversion-focused websites that turn visitors into customers.'
      buttons={false}
      sm
    />
  )
}

export default Hero
