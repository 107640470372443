import React from 'react'
import { Button, Container, Title } from '@UI'
import { BgZoom, Fade } from '@animations'
import { useCurrentWidth } from '@hooks'

import * as styles from './heroBlock.module.scss'

const HeroBlock = ({
  image,
  titleTop,
  titleBottom,
  body,
  sm = false,
  buttons = true,
}) => {
  const width = useCurrentWidth()

  return (
    <BgZoom image={image} sm={sm}>
      <Container wrapper section isSection>
        <Title tag='h1' shadow lg>
          <span className={styles.titleOverflow}>
            <Fade inline delay={0.6} y={30} className={styles.fade}>
              {titleTop}{' '}
            </Fade>
          </span>

          {titleBottom && (
            <span className={styles.titleOverflow}>
              <Fade
                inline
                delay={width < 770 ? 0.6 : 0.9}
                y={30}
                className={styles.fade}
              >
                {titleBottom}
              </Fade>
            </span>
          )}
        </Title>

        {body && (
          <Fade delay={1.2}>
            <p className={styles.body}>{body}</p>
          </Fade>
        )}

        {buttons && (
          <div className={styles.buttonsContainer}>
            <Fade delay={body ? 1.5 : 1.2}>
              <Button cta to='/contact' className={styles.button}>
                Get a Free Consultation
              </Button>
            </Fade>

            <Fade delay={body ? 1.8 : 1.5}>
              <Button to='/services'>Learn More</Button>
            </Fade>
          </div>
        )}
      </Container>
    </BgZoom>
  )
}

export default HeroBlock
