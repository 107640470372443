import React from 'react'
import { ThirdsColorBlock } from '@blocks'
// import * as styles from './stats.module.scss'

const data = [
  {
    title: '57%',
    body: 'Of all U.S. online traffic comes from smartphones and tablets',
  },
  {
    title: '83%',
    body: 'Of mobile users say that a seamless experience across all devices is very important',
  },
  {
    title: '57%',
    body: 'Of users say they won’t recommend a business with a poorly designed mobile site',
  },
]

const Stats = () => {
  return <ThirdsColorBlock data={data} />
}

export default Stats
