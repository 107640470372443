import React from 'react'
import { Checklist as List, Gradient, Container } from '@UI'
import { TitleBodyBlock } from '@blocks'
import * as styles from './checklist.module.scss'

const data = [
  <>
    <b>Fast</b>. Because users will leave a website if it doesn't load quickly
    enough, all our websites are optimized for fast page load speeds.
  </>,
  <>
    <b>Secure</b>. Static sites are inherently more secure than dynamic ones. We
    also use current technologies, and lock our sites down with an SSL
    certificate on launch.
  </>,
  <>
    <b>Lightweight</b>. Since our sites are lighter-weight and don’t depend on
    databases to render, lower-cost hosting options are available.
  </>,
]

const Checklist = () => {
  return (
    <section className={styles.container}>
      <Gradient />
      <TitleBodyBlock
        label='Incredible performance and security'
        title='A Look Behind the Scenes'
        noSection
        subtitle={
          <Container textBlock>
            <p>
              We build our websites using{' '}
              <a
                href='https://www.gatsbyjs.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Gatsby JS
              </a>
              , a static site generator that provides all the security of a
              regular static site and also includes improved functionality.
            </p>
            <p>
              Contact forms, newsletter sign ups, and blogs are just some of the
              features Gatsby provides. Couple these with incredible performance
              and lower hosting costs, and a Gatsby project could be the perfect
              solution for your needs.
            </p>
          </Container>
        }
      >
        <List data={data} itemClass={styles.item} listClass={styles.list} />
      </TitleBodyBlock>
    </section>
  )
}

export default Checklist
