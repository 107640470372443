import React from 'react'
import { Container, FlexContainer, FlexItem, Title, Label } from '@UI'
import { Fade } from '@animations'

const TitleBodyBlock = ({
  label,
  title,
  subtitle,
  sm = false,
  md = false,
  lg = false,
  noSection = false,
  children,
}) => {
  return (
    <Container wrapperSmOnMd section isSection={!noSection}>
      <FlexContainer alignStart collapseOnMd>
        <FlexItem>
          {label && (
            <Fade delay={0.7}>
              <Label>{label}</Label>
            </Fade>
          )}
          <Fade>
            <Title sm={sm} md={md} lg={lg}>
              {title}
            </Title>
          </Fade>

          {subtitle && <Fade style={{ marginTop: '2rem' }}>{subtitle}</Fade>}
        </FlexItem>

        <FlexItem>
          <Fade>
            <Container textBlock>{children}</Container>
          </Fade>
        </FlexItem>
      </FlexContainer>
    </Container>
  )
}

export default TitleBodyBlock
