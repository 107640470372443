import React from 'react'
import { Container, FlexContainer, FlexItem, Title, Card, Icon } from '@UI'
import { Fade } from '@animations'
import { useCurrentWidth } from '@hooks'
import * as styles from './bokehCardsBlock.module.scss'

const BokehCardsBlock = ({ title, cardData }) => {
  const width = useCurrentWidth()

  return (
    <Container section isSection className={styles.container}>
      <div className={styles.gradient} />
      <Container wrapper className={styles.inner}>
        <Fade>
          <Title shadow center>
            {title}
          </Title>
        </Fade>

        <FlexContainer collapseOnMd className={styles.flexContainer}>
          {cardData.map(({ title, description, icon }, i) => (
            <FlexItem key={title} className={styles.flexItem}>
              <Fade
                style={{ height: '100%' }}
                y={20}
                delay={width < 1000 ? 0 : i * 0.3}
              >
                <Card>
                  <Icon>{icon}</Icon>
                  <h3>{title}</h3>
                  <p>{description}</p>
                </Card>
              </Fade>
            </FlexItem>
          ))}
        </FlexContainer>
      </Container>
    </Container>
  )
}

export default BokehCardsBlock
