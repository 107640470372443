import React from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Seo, Cta } from '@global'
import {
  Hero,
  Stats,
  Intro,
  Features,
  Checklist,
  IconsGrid,
  Process,
} from '@pages/services'

const ServicesPage = () => (
  <Layout>
    <Seo
      title='Web Design, Development, and Hosting | Stellr Web | Cody, WY'
      description="Whether you're looking for a new, conversion-focused website or need help maintaining your website, Stellr Web has options for you."
    />
    <Helmet encodeSpecialCharacters={false}>
      <script type='application/ld+json'>
        {`
        {
					"@context": "https://schema.org/",
					"@type": "BreadcrumbList",
					"itemListElement": [{
						"@type": "ListItem",
						"position": 1,
						"name": "Services",
						"item": "https://www.stellrweb.com/services"
					}]
				}
        `}
      </script>
    </Helmet>
    <Hero />
    <Stats />
    <Intro />
    <Features />
    <Process />
    <Checklist />
    <IconsGrid />
    <Cta />
  </Layout>
)

export default ServicesPage
