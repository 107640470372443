import React from 'react'
import { motion } from 'framer-motion'
import { Container, Title } from '@UI'
import { Fade } from '@animations'
import * as styles from './process.module.scss'

const data = [
  {
    title: 'Kickoff',
    body: "We learn about your goals, what makes you unique, and your industry's online presence.",
  },

  {
    title: 'Discovery',
    body: "We map out your site's structure with on-page SEO in mind and create wireframes that emphasize usability.",
  },

  {
    title: 'Design',
    body: 'We create high-quality mockups with a focus on your brand and user experience.',
  },

  {
    title: 'Development',
    body: 'We program the website turning designs into code and hook it up to your CMS.',
  },

  {
    title: 'Test',
    body: 'We optimize the content, fix the bugs, make sure it looks good on different browsers and devices.',
  },

  {
    title: 'Deploy',
    body: 'We launch the website, squash any new bugs, and show you how to update your content.',
  },
]

const gridVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { duration: 0.7 } },
}

const Process = () => {
  return (
    <Container bgDark isSection>
      <Container wrapper section>
        <Fade>
          <Title center>The Development Process</Title>
        </Fade>

        <motion.div
          variants={gridVariants}
          initial='hidden'
          whileInView='show'
          viewport={{ once: true }}
          className={styles.grid}
        >
          {data.map(({ title, body }, i) => (
            <motion.div
              variants={itemVariants}
              className={styles.section}
              key={title}
            >
              <div className={styles.number}>
                <p>{i + 1}</p>
              </div>
              <div>
                <Title className={styles.itemTitle} sm tag='h3'>
                  {title}
                </Title>
                <p>{body}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </Container>
    </Container>
  )
}

export default Process
