import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container, Label, Title } from '@UI'
import { Fade, Slide } from '@animations'
import * as styles from './fullImageTextBlock.module.scss'

const FullImageTextBlock = ({ label, title, image, children }) => {
  return (
    <Container isSection bgAccent className={styles.container}>
      <Slide
        from='top'
        className={styles.imageContainer}
        duration={1.2}
        threshold={0.4}
      >
        <GatsbyImage
          className={styles.bgImage}
          image={getImage(image)}
          alt=''
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </Slide>
      <div className={styles.textContainer}>
        <Container section wrapperSm>
          {label && (
            <Fade delay={0.67}>
              <Label>{label}</Label>
            </Fade>
          )}
          <Fade>
            <Title className={styles.title}>{title}</Title>
          </Fade>
          <Fade>{children}</Fade>
        </Container>
      </div>
    </Container>
  )
}

export default FullImageTextBlock
