import React from 'react'
import { BokehCardsBlock } from '@blocks'
import { Rocket, Lock, Code } from '@images/icons'

// import * as styles from './features.module.scss'

const data = [
  {
    title: 'Search Engine Optimized',
    description:
      'We optimize your content, structure your website properly, and submit it to Google for indexing.',
    icon: <Rocket />,
  },
  {
    title: 'Mobile Responsive',
    description:
      'Your website will look good regardless of the device. Responsiveness is a must for Google and for your visitors.',
    icon: <Lock />,
  },
  {
    title: 'Hand-Built Code',
    description:
      'All our websites are built by us from the ground up resulting in fast, light weight websites with zero bloat.',
    icon: <Code />,
  },
]

const Features = () => {
  return (
    <BokehCardsBlock
      cardData={data}
      title='Every Project Is Built on a Solid Foundation'
    />
  )
}

export default Features
