import React from 'react'
import { motion } from 'framer-motion'
import { Container, Grid, Title } from '@UI'
import { Fade } from '@animations'
import * as styles from './iconsGridBlock.module.scss'

const IconsGridBlock = ({ title, gridData }) => {
  return (
    <Container wrapper section isSection>
      <Fade>
        <Title center>{title}</Title>
      </Fade>

      <Grid columns={2} gap='lg' collapseOnMd className={styles.grid}>
        {gridData.map(({ title: cardTitle, description, icon }) => (
          <Fade className={styles.gridItem} key={cardTitle}>
            <motion.span
              className={styles.iconContainer}
              initial={{ scale: 0.9 }}
              whileInView={{ scale: [0.9, 1.1, 1] }}
              transition={{ delay: 0.2, duration: 0.7 }}
            >
              {icon}
            </motion.span>
            <h3 className={styles.itemTitle}>{cardTitle}</h3>
            <p>{description}</p>
          </Fade>
        ))}
      </Grid>
    </Container>
  )
}

export default IconsGridBlock
