import React from 'react'
import { IconsGridBlock } from '@blocks'
import { Server, Tool, Pencil, Analytics } from '@images/icons'
// import * as styles from './iconsGrid.module.scss'

const data = [
  {
    title: 'Low Cost Hosting',
    description:
      'Once your site is built, host it with us and avoid the hassle of server management.',
    icon: <Server />,
  },
  {
    title: 'Hands-Off Maintenance',
    description:
      "We'll keep your site's software and plugins current with all the latest updates and optimization.",
    icon: <Tool />,
  },
  {
    title: 'Content Management',
    description:
      'Keep your content fresh with monthly text and image updates after your site is launched',
    icon: <Pencil />,
  },
  {
    title: 'Insightful Analytics',
    description:
      'Get monthly analytics reports and discover your most viewed content and pages.',
    icon: <Analytics />,
  },
]

const IconsGrid = () => {
  return (
    <IconsGridBlock title='Worry-Free Hosting and Updates' gridData={data} />
  )
}

export default IconsGrid
